<template>
  <div class="fs-32 fw-600 clock-kiosk" >
    &nbsp;
    <template v-if="isReady">
    {{$l.detectLanguage(day)}}, {{date.day}} {{$l.detectLanguage(date.month)}} {{date.year}} {{date.hour}}<span class="blinking">:</span>{{date.minute}}
    </template>
  </div>
</template>

<script>
import ConvertTime from '@/helpers/convertTime'
export default {
  data () {
    return {
      isReady: false,
      day: '',
      date: {
        day: '',
        month: '',
        year: '',
        hour: '',
        minute: '',
        second: ''
      }
    }
  },
  mounted () {
    setInterval(() => {
      this.day = this.convertDate(new Date())
      this.date = this.convertDateTime(new Date())
      this.isReady = true
    }, 1000)
  },
  methods: {
    convertDate (now) {
      return new ConvertTime().getNameDay(now.getDay())
    },
    convertDateTime (now) {
      return {
        day: now.getDate(),
        month: new ConvertTime().getNameMonth(now.getMonth()),
        year: now.getFullYear(),
        hour: now.getHours().toString().padStart(2, '0'),
        minute: now.getMinutes().toString().padStart(2, '0'),
        second: now.getSeconds().toString().padStart(2, '0')
      }
    }
  }
}
</script>
