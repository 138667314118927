<template>
  <div class="dvh-100" :class="isErrorPage || isOffline ? 'image-setup': 'position-relative'">
      <div class="position-absolute position-latency-kiosk">
        <latency-bar-icons :ping-time="pingTime" :config-color="configColor"/>
      </div>
        <header-antrian :config-data="stateLoad.context.config.cluster"></header-antrian>
        <router-view/>
    </div>
   <overlay v-show="isErrorPage || isOffline">
     <div class="w-100 ">
      <overlay-header :icon="icon" :header-text="$l.detectLanguage('sorrySystem')" />
     </div>
     <overlay-info />
     <overlay-footer count-down/>
  </overlay>
</template>
<script>
import HeaderAntrian from '@/views/HeaderAntrian'
import { mapActions, mapState, mapMutations } from 'vuex'
import Overlay from '@/components/Overlay/Overlay.vue'
import OverlayHeader from '@/components/Overlay/OverlayHeader'
import OverlayFooter from '@/components/Overlay/OverlayFooterProvider'
import OverlayInfo from '@/components/Overlay/OverlayInfoProvider.vue'
import { KioskAntrianMachine } from '@/machine/machineKioskAntrian'
import { assign } from 'xstate'
import { useMachine } from '@xstate/vue'
import ENUM_SETUP from '@/enum/typeSetup'
import LatencyBarIcons from '@/components/Icon/LatencyBarIcons.vue'
import kioskAntrianRepository from '@/repositories/KioskAntrian'
import { Repository } from '@/repositories/repository'
// import PrintData from '@/mappers/printBody'
import KioskAntrianMapper from '@/mappers/KioskAntrianMapper'
import MoreFunction from '@/helpers/moreFunction'
import moment from 'moment'
const fromServer = Repository.get('fromServer')
// import indexDB from '@/repositories/Kiosk'
export default {
  components: {
    HeaderAntrian,
    OverlayHeader,
    OverlayFooter,
    Overlay,
    OverlayInfo,
    LatencyBarIcons
  },
  data () {
    const that = this
    window.kioskAntrianMachine = KioskAntrianMachine.withConfig({
      services: {
        // playUntilDone
        getLabelBadge: () => {
          return kioskAntrianRepository.getAllLabels()
        },
        getAndSetVideo: (state, evt) => {
          // console.log(state)
          // console.log('init dipanggil')
          const linkVideo = state.config.cluster.video
          return that.getVideo(linkVideo)
        },
        getListAntrian: (context, event) => {
          const selectUnit = context.selectUnit && context.selectUnit.id ? parseInt(context.selectUnit.id) : parseInt(context.listUnit[0].id)
          const ticket = context.listQueue.ticketNo
          return new Promise((resolve) => {
            let objnow = []
            objnow = kioskAntrianRepository.objToUnit()
            kioskAntrianRepository.getOrderLines(selectUnit, ticket, objnow, context.listBadge).then(_resp => {
              window.machineKiosk.send({
                type: 'on:create:unit',
                data: _resp
              })
            })
          })
        },
        openIndexDB: (state, evt) => {

        },
        postQueuePendaftaranMultiple: (context, _event) => {
          if (this.timeOutKiosk)clearTimeout(this.timeOutKiosk)
          const listPasien = context.selectPasienMultiple
          const indexPasien = _event.index ?? 0
          const selectedPasien = listPasien[indexPasien]
          let reservasiPatient = {}
          let reservasiIds = []
          if (selectedPasien && selectedPasien.list) {
            const data = selectedPasien.list.map(_item => {
              return {
                ..._item
              }
            })
            reservasiPatient = KioskAntrianMapper.choisePasien(data)
            reservasiIds = selectedPasien.list.map(_item => _item.id)
          }
          return new Promise((resolve) => {
            const listResponse = _event.data ?? []
            kioskAntrianRepository.sendToAPI({
              unitId: parseInt(context.selectPendaftaran.id),
              reservasiPatient,
              ...(reservasiIds.length > 0 ? { reservasiIds } : {})
            }).then()
              .catch(() => {
                selectedPasien.type = 'FAILED'
                resolve({
                  index: indexPasien,
                  data: listResponse
                })
              })

            this.timeOutKiosk = setTimeout(() => {
              selectedPasien.type = 'FAILED'
              resolve({
                index: indexPasien,
                data: listResponse
              })
            }, 20000)
            window.socket.once('QUEUE:CREATE', async (_evt) => {
              const data = {}
              data.ticket = _evt.ticketNo
              data.cluster = `${context.config.cluster.floor} ${context.config.cluster.nama}`
              data.generatedId = _evt.generatedId
              data.createdAt = _evt.CreatedAt
              listResponse.push(data)
              resolve({
                index: indexPasien,
                data: listResponse
              })
            })
          })
        },
        postQueuePendaftaran: (context) => {
          // kioskAntrianRepository.
          let reservasiPatient = {}
          let reservasiIds = []
          if (context.selectPasien && context.selectPasien.list) {
            const data = context.selectPasien.list.map(_item => {
              return {
                ..._item
              }
            })
            reservasiPatient = KioskAntrianMapper.choisePasien(data)
            reservasiIds = context.selectPasien.list.map(_item => _item.id)
          }
          return new Promise((resolve) => {
            // const selectPendaftaran = context.selectPendaftaran && context.selectPendaftaran.id ? parseInt(context.selectPendaftaran.id) : parseInt(context.listPendaftaranWithType[0].id)
            kioskAntrianRepository.sendToAPI({
              unitId: parseInt(context.selectPendaftaran.id),
              reservasiPatient,
              ...(reservasiIds.length > 0 ? { reservasiIds } : {})
              // ...(context.listBooking ? { infoPasien: context.listBooking[0] } : {})
            })
            // window.socket.once('QUEUE:UPDATE', async (_evt) => {
            window.socket.once('QUEUE:CREATE', async (_evt) => {
              let data = {}
              try {
                let objShow = []
                if (context.typeUnit === ENUM_SETUP.TYPE_UNIT) {
                  objShow = kioskAntrianRepository.objToUnit()
                } else {
                  objShow = kioskAntrianRepository.objPendaftaran()
                }
                // console.log('masukk ', objShow)
                data = await kioskAntrianRepository.getOrderLines(context.selectPendaftaran.id, _evt.ticketNo, objShow, context.listBadge)
                data.ticket = _evt.ticketNo
                data.cluster = `${context.config.cluster.floor} ${context.config.cluster.nama}`
                data.generatedId = _evt.generatedId
                data.createdAt = _evt.CreatedAt
                // console.log('=====', data)
                window.machineKiosk.send({
                  type: 'on:create:pendaftaran',
                  data
                })
              } catch {
                window.machineKiosk.send({
                  type: 'on:create:pendaftaran',
                  data
                })
              }
              // resolve(true)
            })
          })
        },
        getInfoBooking: (context) => {
          const isCode = !((/^62|^08/).test(context.key) && context.key.length > 10)
          return new Promise((resolve, reject) => {
            kioskAntrianRepository.getInfoTicket({ key: context.key, isCode }).then(_data => {
              // console.log('kenapa gak masuk kesini', _data)
              window.machineKiosk.send({
                type: 'on:info:booking',
                data: _data
              })
              // console.log('gak masuk sepertinya')
              resolve(true)
            }).catch(err => {
              // console.log('error kemari')
              reject(err)
            })
          })
        },
        getInfoTicket: (context, _evt) => {
          // info ticket
          return new Promise((resolve, reject) => {
            const generatedId = _evt.data && _evt.data.generateId ? _evt.data.generateId : context.modeTicket.generateId
            kioskAntrianRepository.getAntrianWithGenerate(generatedId).then(_resp => {
              const dateReserv = moment(_resp.date)
              if (moment().diff(dateReserv, 'days') === 0 && (!_resp.status || _resp.status !== 'REMOVE')) {
                window.machineKiosk.send({
                  type: 'on:info:ticket',
                  data: _resp
                })
              } else {
                reject(new Error('NOT DATA'))
              }
            }).catch(err => {
              reject(err)
            })
          })
        },
        printTicketMultiple: async (context, _evt) => {
          const indexData = _evt.index ?? 0
          const dataPrint = _evt.dataPrint[indexData]
          const body = await KioskAntrianMapper.bodyPrint(
            {
              ...(context.selectPasienMultiple[indexData] && context.selectPasienMultiple[indexData].list ? { dokter: KioskAntrianMapper.getListBookingMapper(context.selectPasienMultiple[indexData].list) } : {}),
              antrian: dataPrint.ticket,
              cluster: dataPrint.cluster,
              generatedId: dataPrint.generatedId,
              createdAt: dataPrint.createdAt,
              headerPrint: context.config && context.config.headerPrint ? context.config.headerPrint : ''
            })
          return fromServer.printKiosk(body)
            .then(() => {
              setTimeout(() => {
                if (indexData + 1 < _evt.dataPrint.length) {
                  this.service.send({
                    type: 'print:after',
                    dataPrint: _evt.dataPrint,
                    index: indexData + 1
                  })
                }
              }, 1000)
            }).then().catch(err => {
              const responseStatus = err.response.status
              if (responseStatus === 410) {
                // this.getStatus()
              }
            })
        },
        printTicket: async (context, _evt) => {
          const body = await KioskAntrianMapper.bodyPrint(
            {
              ...(context.selectPasien && context.selectPasien.list ? { dokter: KioskAntrianMapper.getListBookingMapper(context.selectPasien.list) } : {}),
              ...(context.listBooking && context.listBooking.length > 0 ? { dokter: context.listBooking } : {}),
              antrian: _evt.data.ticket,
              cluster: _evt.data.cluster,
              generatedId: _evt.data.generatedId,
              createdAt: _evt.data.createdAt,
              headerPrint: context.config && context.config.headerPrint ? context.config.headerPrint : ''
            })
          return fromServer.printKiosk(body)
            .then().catch(err => {
              const responseStatus = err.response.status
              if (responseStatus === 410) {
                // this.getStatus()
              }
            })
        },
        getStatusPrinterUSB: (context) => {
          return new Promise((resolve, reject) => {
            if (context.errorPrinter) {
              resolve(true)
            } else {
              fromServer.getStatusPrinter()
                .then(_resp => _resp.data)
                .then(_data => {
                  let isTimeOutRun = true
                  const moreFunction = new MoreFunction()
                  if (_data && _data.cover === 'Cover opened') {
                    resolve(ENUM_SETUP.OTHER_ERROR_PRINT)
                    moreFunction.createToastPrinter(true, ENUM_SETUP.OTHER_ERROR_PRINT)
                  } else if (_data && _data.paperstatus === 'roll paper undefined') {
                    moreFunction.createToastPrinter(false)
                    resolve('')
                    isTimeOutRun = false
                  } else if (_data && _data.paperstatus === 'ran out of paper') {
                    resolve(ENUM_SETUP.EMPTY_PAPER)
                    moreFunction.createToastPrinter(true, ENUM_SETUP.EMPTY_PAPER)
                  } else {
                    resolve(ENUM_SETUP.OTHER_ERROR_PRINT)
                    moreFunction.createToastPrinter(true, ENUM_SETUP.OTHER_ERROR_PRINT)
                  }
                  if (isTimeOutRun) {
                    this.timeOutPrinter = setTimeout(() => {
                      this.getStatus()
                    }, 5000)
                  }
                })
                .catch(_err => {
                // eslint-disable-next-line prefer-promise-reject-errors
                  reject(ENUM_SETUP.OTHER_ERROR_PRINT)
                  this.timeOutPrinter = setTimeout(() => {
                    this.getStatus()
                  })
                })
            }
          })
        },
        getStatusPrinter: () => {
          return new Promise((resolve, reject) => {
            fromServer.getStatusPrinter()
              .then(_resp => _resp.data)
              .then(_data => {
                if (this.timeOutPrinter) clearTimeout(this.timeOutPrinter)
                const moreFunction = new MoreFunction()
                let isTimeOutRun = true
                if (_data && _data.cover === 'Cover opened') {
                  resolve(ENUM_SETUP.OTHER_ERROR_PRINT)
                  moreFunction.createToastPrinter(true, ENUM_SETUP.OTHER_ERROR_PRINT)
                } else if (_data && _data.paperstatus === 'roll paper undefined') {
                  moreFunction.createToastPrinter(false)
                  isTimeOutRun = false
                  resolve('')
                } else if (_data && _data.paperstatus === 'ran out of paper') {
                  resolve(ENUM_SETUP.EMPTY_PAPER)
                  moreFunction.createToastPrinter(true, ENUM_SETUP.EMPTY_PAPER)
                } else {
                  resolve(ENUM_SETUP.OTHER_ERROR_PRINT)
                  moreFunction.createToastPrinter(true, ENUM_SETUP.OTHER_ERROR_PRINT)
                }
                if (isTimeOutRun) {
                  this.timeOutPrinter = setTimeout(() => {
                    this.getStatus()
                  }, 5000)
                }
              })
              .catch(_err => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(ENUM_SETUP.OTHER_ERROR_PRINT)
                if (this.timeOutPrinter) clearTimeout(this.timeOutPrinter)
                this.timeOutPrinter = setTimeout(() => {
                  this.getStatus()
                }, 5000)
              })
          })
        },
        sendErrorPrint: (context) => {
          return fromServer.errorSlack(context)
        },
        postQueueUnit: (context, evt) => {
          // post to unit selectUnit ketika scan ambil dari listUnit ke array 0
          const selectUnit = context.selectUnit && context.selectUnit.id ? parseInt(context.selectUnit.id) : parseInt(context.listUnit[0].id)
          return new Promise((resolve, reject) => {
            kioskAntrianRepository.checkInQueue({
              unit: selectUnit,
              generatedId: context.detailList.generatedId
            }).then(_resp => {
              // window.socket.once('RESPONSE:API-CHECKIN', async (_evt) => {
              window.socket.once('RESPONSE:API-MOVE', async (_evt) => {
                // console.log('piost unit', _evt)
                if (!_evt.id) {
                  reject(new Error('NULL DATA'))
                }
                // let data = {}
                window.machineKiosk.send({
                  type: 'go:list'
                })
                try {
                  // data = await kioskAntrianRepository.getOrderLines(4, '0008')
                  // window.machineKiosk.send({
                  //   type: 'on:create:unit',
                  //   data
                  // })
                } catch {
                  // window.machineKiosk.send({
                  //   type: 'on:create:unit',
                  //   data
                  // })
                }
              })
            }).catch(err => {
              // console.log('error', err)
              reject(err.data)
            })
          })
        },
        downloadList: (state, evt) => (callback) => {
          for (let i = 1; i <= 5; i++) {
            setTimeout(() => {
              // callback({
              //   type: 'INC',
              //   data: i
              // })
            }, 1000 * i)
          }
        },
        getDataAntrian: (state, _event) => {
          return new Promise((resolve) => {
            kioskAntrianRepository.getAntrianWithGenerate(_event.data).then(_resp => {
              const mapperPrint = KioskAntrianMapper.mapperPrintTicket(_resp)
              resolve(mapperPrint)
            })
          })
        },
        postNewGeneratedId: (state, _event) => {
          return new Promise((resolve) => {
            const generatedId = _event.data.generatedId
            kioskAntrianRepository.regenerateTicket(generatedId)
              .then(_resp => {
                window.socket.once('RESPONSE:QUEUE:REPRINT', (_evt) => {
                  const newGenerated = _evt.newGeneratedId
                  // console.log('new', newGenerated)
                  resolve({
                    ..._event.data,
                    generatedId: newGenerated
                  })
                })
              })
          })
        },
        printTicketQR: async (context, _event) => {
          const _evt = _event.data
          const body = await KioskAntrianMapper.bodyPrint(
            {
              ...(_evt.dokter.length > 0 ? { dokter: _evt.dokter } : {}),
              antrian: _evt.antrian,
              generatedId: _evt.generatedId,
              createdAt: _evt.createdAt,
              reprinted: _evt.reprinted,
              headerPrint: context.config && context.config.headerPrint ? context.config.headerPrint : ''
            })
          return fromServer.printKiosk(body)
        }
      },
      actions: {
        setCtxErrorPrinter: assign({
          errorPrinter: (context, _event) => {
            return _event.data
          }
        }),
        setCtxOnlyCheckInTicket: assign({
          OnlyPrintTicket: () => true
        }),
        setCtxBackCheckInTicket: assign({
          OnlyPrintTicket: () => false
        }),
        setCtxLabelBadge: assign({
          listBadge: (context, _event) => _event.data
        }),
        deleteCtxSelected: assign(() => {
          return {
            listBooking: [],
            selectPasien: {},
            selectPasienMultiple: [],
            // selectUnit: {},
            key: '',
            listTable: {},
            listBookingWithPasien: []
          }
        }),
        setCtxConfiguration: assign({
          config: (_context, _event) => {
            const data = JSON.parse(localStorage.getItem('config'))
            return data
          }
        }),
        setCtxConfigNew: assign({
          config: (_context, _event) => {
            return _event.data
          }
        }),
        setCtxPageMenu: () => {
          that.$router.push({ name: 'Menu' })
        },
        setCtxPageIdle: () => {
          that.$router.push({ name: 'kioskAntrian' })
        },
        setCtxPageLoading: () => {
          that.$router.push({ name: 'loading' })
        },
        setCtxPageSuccessPendaftaran: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_ANTRIAN_SUCCESS
          }
        }),
        setCtxPageSuccessQueue: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_ANTRIAN_LIST_SUCCESS
          }
        }),
        setCtxPageInputKeypad: () => {
          that.$router.push({ name: 'inputKeypad' })
        },
        setCtxPageErrorQueue: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_ANTRIAN_ERROR
          }
        }),
        setCtxPageErrorServer: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_SERVER_ERROR
          }
        }),
        setCtxPageErrorPrinter: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_PRINTER_ERROR
          }
        }),
        setCtxPageBookingList: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_ANTRIAN_LIST_DOKTER
          }
        }),
        setCtxPageDoubleListPhone: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_DOUBLE_PASIEN
          }
        }),
        setCtxPageInfoQueue: assign({
          page: (_context) => {
            that.$router.push({ name: 'showTicket' })
            return ENUM_SETUP.PAGE_MODE_ANTRIAN
          }
        }),
        // that.$router.push({ name: 'showTicket' })
        // },
        setCtxUnit: assign({
          listUnit: (_context, _event) => {
            // console.log('masukk unit')
            // console.log(_context, '<<<<<<<<<<<<<<<<')
            const options = _context.config.option
            const unitSelect = options.filter(item => item.type === ENUM_SETUP.TYPE_UNIT)
            const list = []
            unitSelect.forEach(item => {
              list.push(...item.child)
            })
            return list
          }
        }),
        setCtxListPendaftaran: assign({
          listPendaftaran: (_context, _event) => {
            // console.log('masukk pendaftaran')
            const options = _context.config.option
            const pendaftaran = options.reduce((data1, data2) => {
              if (data2.type === ENUM_SETUP.TYPE_WALK_IN) {
                data1[ENUM_SETUP.TYPE_WALK_IN] = data2.child
              } else if (data2.type === ENUM_SETUP.TYPE_BOOKING) {
                data1[ENUM_SETUP.TYPE_BOOKING] = data2.child
              }
              return data1
            }, {})
            return pendaftaran
          }
        }),
        setCtxSetListUnit: assign({
          modalListConfirm: (_context) => {
            return _context.listUnit
          }
        }),
        setCtxModalOpenOther: assign({
          modalOther: () => true
        }),
        setCtxModalSingleSelect: assign({
          modalSingle: () => true
        }),
        setCtxModalCloseSingleSelect: assign({
          modalSingle: () => false
        }),
        setCtxModalAttentionSingle: assign({
          modalSingle: () => true,
          isDataNotPendaftaran: () => true
        }),
        setCtxModalCloseAttentionSingle: assign({
          modalSingle: () => false,
          isDataNotPendaftaran: () => false
        }),
        setCtxModalCloseOther: assign({
          modalOther: () => false
        }),
        setCtxShowModalGIF: assign({
          modalUnit: () => true
        }),
        setCtxHideModalGIF: assign({
          modalUnit: () => false
        }),
        setCtxModeTicket: assign({
          modeTicket: (_context, _event) => {
            return _event.data
          }
        }),
        setCtxListMenu: assign({
          listMenu: (_context) => {
            const options = _context.config.option
            return options
          }
        }),
        // setCtxObjSelectUnitPendaftaran: assign({
        //   selectUnit: (context) => {
        //     const selectLine = context.detailList.orderList
        //     return {
        //       id: selectLine[0].unit.id,
        //       name: selectLine[0].unit.name
        //     }
        //   }
        // }),
        setCtxTypeActive: assign({
          typeUnit: (_context, _event) => {
            return _event.data.type
          }
        }),
        setCtxListQueue: assign({
          listTable: (_context, _event) => {
            return _event.data
          }
        }),
        setCtxTicketMe: assign({
          ticketInfo: (_context, _event) => {
            return _event.data.ticket
          }
        }),
        setCtxModalListPendaftaran: assign({
          modalListConfirm: (_context, _event) => {
            return _context.listPendaftaranWithType
          }
        }),
        setCtxListWithType: assign({
          listPendaftaranWithType: (_context) => {
            if (_context.typeUnit === ENUM_SETUP.TYPE_BOOKING) {
              return _context.listPendaftaran[ENUM_SETUP.TYPE_BOOKING]
            } else if (_context.typeUnit === ENUM_SETUP.TYPE_WALK_IN) {
              return _context.listPendaftaran[ENUM_SETUP.TYPE_WALK_IN]
            } else if (_context.typeUnit === ENUM_SETUP.TYPE_UNIT) {
              return _context.listUnit
            } else {
              return []
            }
          }
        }),
        setCtxSelectPendaftaran: assign({
          selectPendaftaran: (_context, _event) => {
            return _event.data
          }
        }),
        setCtxPendaftaranFromUnit: assign({
          selectPendaftaran: (_context) => {
            const selectUnit = _context.selectUnit && _context.selectUnit.name ? _context.selectUnit : (_context.selectUnit && _context.selectUnit.list ? _context.selectUnit.list[0] : _context.listUnit[0])
            return selectUnit
          }
        }),
        selectCtxPendaftaranOne: assign({
          selectPendaftaran: (_context, _event) => {
            return _context.listPendaftaranWithType[0]
          }
        }),
        setCtxSelectUnit: assign({
          selectUnit: (_context, _event) => {
            return _event.data
          }
        }),
        setCtxTicketQueue: assign({
          listQueue: (context, _event) => {
            // console.log('select qeueue')
            return _event.data
          }
        }),
        setCtxStrKeyCode: assign({
          key: (context) => {
            return context.ticketObj.code
          }
        }),
        addCtxInputKey: assign({
          key: (context, event) => {
            return event.data.key
          }
        }),
        setCtxInputToZero: assign({
          key: () => {
            return ''
          }
        }),
        setCtxListBooking: assign({
          listBooking: (context, _event) => {
            const listDokter = KioskAntrianMapper.getListBookingMapper(_event.data)
            return listDokter
          },
          selectPasien: (context, _event) => {
            return {
              id: _event.data[0].id,
              nama: _event.data[0].namaPasien,
              list: [..._event.data]
            }
          }
        }),
        setCtxListBookingWithPasient: assign({
          listBookingWithPasien: (context, _event) => {
            const listPasien = KioskAntrianMapper.getListPasienMapper(_event.data)
            // console.log('listPasien', listPasien)
            return listPasien
          }
        }),
        setCtxDisabledButton: assign({
          buttonDisabled: () => true
        }),
        setCtxEnabledButton: assign({
          buttonDisabled: () => false
        }),
        setCtxSelectPasient: assign({
          selectPasien: (_context, _pasient) => {
            // console.log('seelect pasien error data yea')
            return _context.listBookingWithPasien[0]
          }
        }),
        setCtxPasient: assign({
          selectPasienMultiple: (_context, _pasient) => {
            const listPasien = _context.selectPasienMultiple ?? []
            const selectPasien = _pasient.data
            const index = listPasien.findIndex(_item => _item && _item.id === selectPasien.id)
            if (index < 0) {
              listPasien.push(selectPasien)
            } else {
              listPasien.splice(index, 1)
            }
            return [...listPasien]
          }
        }),
        setCtxToBooking: assign({
          listBooking: (context, event) => {
            // console.log(context.selectPasien)
            const listDokter = KioskAntrianMapper.getListBookingMapper(context.selectPasien.list)
            return listDokter
          }
        }),
        setCtxKeyCheckNumber: assign({
          key: (context) => {
            if ((/^08/).test(context.key) && context.key && context.key.toString().length < 32) {
              return context.key.replace(/^08/, '628')
            }
            return context.key
          }
        }),
        setCtxObjTicketResult: assign({
          ticketObj: (context, _event) => {
            return { ..._event.data }
          }
        }),
        setCtxQueueList: assign({
          detailList: (context, event) => {
            const selectUnit = context.selectUnit && context.selectUnit.id ? context.selectUnit : context.listUnit[0]
            const idSelectUnit = parseInt(selectUnit.id)
            const list = context.listQueue.orderLines
            const haveListDokter = list.filter(_item => _item.unit && _item.unit.type === 'DOKTER' && _item.status !== 'ARRIVE')
            const isRegist = context.listQueue.visitNo || haveListDokter.length > 0
            const doubleCheck = list.filter(item => item.unit.id === idSelectUnit && item.status !== 'FINISH')
            let waiting = list.filter(_item => _item.addToQueue && _item.status === 'ARRIVE' && _item.unit.type === 'DOKTER')
            if (waiting.length > 0) {
              waiting = KioskAntrianMapper.mapperDokter(waiting)
            }
            const booking = []
            return {
              numberTicket: context.listQueue.ticketNo,
              generatedId: context.listQueue.generatedId,
              orderList: list,
              waiting,
              booking,
              lengthData: waiting.length + booking.length,
              doubleCheck: doubleCheck.length > 0,
              selectUnit,
              isRegist
            }
          }
        }),
        setCtxSuccessTicket: assign({
          successTicket: () => true
        }),
        setCtxFailedTicket: assign({
          successTicket: () => false
        }),
        setCtxDoubleCheck: assign({
          doubleCheck: () => {
            // console.log('double check')
            return true
          }
        }),
        setCtxNotRegist: assign({
          doubleCheck: () => {
            return false
          }
        }),
        setCtxSuccessMultipleTicket: async (context, _event) => {
          const listPasien = context.selectPasienMultiple
          const listResponse = _event.data.data
          const indexPasien = _event.data.index ?? 0
          let objShow = []
          if (listPasien.length - 1 === indexPasien) {
            if (this.timeOutKiosk)clearTimeout(this.timeOutKiosk)
            const onlyUnique = (value, index, array) => {
              return array.indexOf(value) === index
            }
            const listTicket = listResponse.map(_item => _item.ticket).filter(onlyUnique)
            objShow = kioskAntrianRepository.objPendaftaran()
            const dataMultiple = await kioskAntrianRepository.getOrderLines(context.selectPendaftaran.id, listTicket, objShow, context.listBadge)
            window.machineKiosk.send({
              type: 'on:create:pendaftaran',
              data: dataMultiple,
              dataPrint: listResponse
            })
          } else {
            window.machineKiosk.send({
              type: 'after:pasien',
              data: listResponse,
              index: indexPasien + 1
            })
          }
        }
      },
      guards: {
        isStop: () => false,
        isPrinterOK: (_context) => !_context.errorPrinter,
        isHaveUnitOnlyOne: (_context) => {
          return _context.listPendaftaranWithType.length === 1
        },
        isNotHaveUnit: (_context) => {
          return _context.listPendaftaranWithType.length === 0
        },
        isCodeBooking: (_context, _event) => {
          return _context.modeTicket.type === ENUM_SETUP.TYPE_BOOKING
        },
        isCodeTicket: (_context, _event) => {
          return _context.modeTicket.type === ENUM_SETUP.TYPE_UNIT
        },
        isTypeUnit: (_context, _event) => {
          return _event.data.type === ENUM_SETUP.TYPE_UNIT
        },
        isTypeBooking: (_context, _event) => {
          return _event.data.type === ENUM_SETUP.TYPE_BOOKING
        },
        isTypeWalkIn: (_context, _event) => {
          if (_event.data) {
            return _event.data.type === ENUM_SETUP.TYPE_WALK_IN
          }
          return _context.typeUnit === ENUM_SETUP.TYPE_WALK_IN
        },
        isHavePendaftaranOnlyOne: (_context, _event) => {
          return _context.listPendaftaranWithType.length === 1
        },
        isNotHavePendaftran: (_context) => {
          return _context.listPendaftaranWithType.length === 0
        },
        isListQueue: (_context) => {
          // return false
          return _context.detailList.lengthData > 0
        },
        isCode: (_context) => {
          const code = _context.key
          const isNoHape = (/^62|^08/).test(code) && code.length > 10
          return !isNoHape
        },
        isBookingHaveOne: (_context) => {
          return _context.listBooking.length === 1
        },
        isPatientOnlyOne: (_context) => {
          // console.log('length Pasieng')
          return _context.listBookingWithPasien.length === 1
        },
        isEnable: (_context) => {
          return _context.selectPasienMultiple.length > 0
        },
        isWithCheck: (_context) => {
          return _context.detailList.doubleCheck
        },
        isNotRegistration: (_context) => {
          // return false
          const isRegist = _context.detailList.isRegist
          return !isRegist
        },
        isServer500: (_context, _event) => {
          if (_event.data && (_event.data.message === 'NOT DATA' || _event.data.status === 404 || _event.data.queueId)) return false
          return true
        }
        // hasPlaylist: (_context, _evt) => {
        //   return _context.listVideoFromConfig
        // },
        // isAllDownload: (_context, _event) => {
        //   return _context.listVideo && _context.listVideoFromConfig.length === _context.listVideo.length
        // },
        // isVideoNotPlay: (_context, _event) => {
        //   return !_context.isPlayVideo
        // },
        // hasDownloadPlaylist: (_context, _event) => {
        //   return !_context.isPlayVideo && _context.listVideo && _context.listVideo.length > 0
        // },
        // isPlayingVideoNotValid: (_context, _event) => {
        //   // return _context.listVideo
        //   return false
        // },
        // isNoTReady: () => {
        //   return false
        // }

      },
      delays: {
        timeOutCancel: (context) => {
          // console.log(context.config.delay.timeOutCancel, '<<---')
          return parseInt(context.config.delay.timeOutCancel) * 1000
        },
        timeOutLinear: (context) => {
          const { buttonLinearCancelDelay, buttonLinearCancelProgress, buttonLinearCancelAfter } = context.config.delay
          return (parseInt(buttonLinearCancelDelay) + parseInt(buttonLinearCancelProgress) + parseInt(buttonLinearCancelAfter)) * 1000
        },
        timeToIdlePage: (context) => {
          return parseInt(context.config.delay.timeOutToIdle) * 1000
        },
        waitResponse: (context) => {
          const delayResponse = context.config.delay.waitResponseApi ?? 15
          return parseInt(delayResponse) * 1000
        }
      }
    })
    const modeDevTools = process.env.VUE_APP_DEVELOPMENT ? { devTools: true } : { devTools: false }
    const { service, state } = useMachine(window.kioskAntrianMachine, modeDevTools)
    window.machineKiosk = service
    window.stateMachineKiosk = state
    return {
      timeOutKiosk: null,
      timeOutPrinter: null,
      service,
      stateLoad: state,
      modalActive: false,
      icon: require('@/assets/gif/cry-offline.gif'),
      // icon: require('@/assets/icon/error.svg'),
      // runTimeOut: () => {},
      runKeyUpScanner: null,
      timeRemoveTempText: null,
      tempText: '',
      resultText: ''
    }
  },
  computed: {
    ...mapState({
      dataTimeOut: state => state.dataTimeOut
    }),
    ...mapState('DocumentHandler', {
      isErrorPage: state => state.isErrorPage,
      isOffline: state => state.isOffline,
      pingTime: state => state.pingTime
    }),
    configColor () {
      return this.stateLoad && this.stateLoad.context && this.stateLoad.context.config.serverConfig && this.stateLoad.context.config.serverConfig.latencyIndicator ? this.stateLoad.context.config.serverConfig.latencyIndicator : {}
    }
  },
  methods: {
    getStatus () {
      this.service.send({
        type: 'status:printer'
      })
    },
    ...mapActions({
      getDataTimeOut: 'CHANGE_DATA_TIME_OUT',
      getVideo: 'GET_VIDEO_IN_INDEXDB_AND_REPO'
    }),
    ...mapMutations({
      setOffline: 'DocumentHandler/SET_IS_OFFLINE'
    }),
    waitToHomeScreen () {
      if (this.runTimeOut) clearTimeout(this.runTimeOut)
      this.runTimeOut = setTimeout(() => {
        if (this.$route.name && this.$route.name !== 'Home') {
          // this.$router.push({ name: 'Home' })
        }
      }, this.dataTimeOut.idleTime * 1000)
    },
    // upDateOnlineStatus () {
    //   var condition = !!navigator.onLine
    //   if (!condition) {
    //     this.setOffline(true)
    //   } else {
    //     this.setOffline(false)
    //   }
    // },
    removeConfig () {
      localStorage.removeItem('configPoli')
      this.$router.push({ name: 'Setup' })
    }
    // createToast () {
    //   if (!document.getElementById('toast-error')) {
    //     const bodyTag = document.body
    //     const element = document.createElement('label')
    //     element.textContent = 'QR code tidak dikenali'
    //     const classList = ['bg-warning-A', 'fw-600', 'fs-32', 'label-toast']
    //     element.classList.add(...classList)
    //     element.id = 'toast-error'
    //     bodyTag.appendChild(element)
    //     setTimeout(() => {
    //       document.getElementById('toast-error').classList.add('label-toast-show')
    //     }, 100)
    //     setTimeout(() => {
    //       document.getElementById('toast-error').classList.remove('label-toast-show')
    //     }, 5000)
    //     setTimeout(() => {
    //       document.getElementById('toast-error').remove()
    //     }, 6000)
    //   }
    // }
  }
}
</script>
