<template>
 <clock />
  <div class="header-antrian">
    <label class="kiosk mb-0">{{$l.detectLanguage('antrian')}} </label>
    <label class="info-kiosk mb-0 fs-42">{{configData.nama}} | {{configData.floor}} </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Clock from '../components/Menu/Clock.vue'

export default {
  props: {
    configData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Clock
  },
  data () {
    return {
      numberPola: 0,
      isTimeOut: '',
      isTimeOutPola: '',
      isPolaCount: false
    }
  },
  computed: {
    // ...mapState({
    //   config: state => state.config
    // })
  },
  methods: {
    secretButton () {
      if (this.isTimeOut) clearTimeout(this.isTimeOut)
      if (this.isTimeOutPola) clearTimeout(this.isTimeOutPola)
      this.numberPola++
      if (this.numberPola === 3) {
        this.isTimeOutPola = setTimeout(() => {
          this.isPolaCount = true
        }, 1000)
      }
      if (this.numberPola === 4 && this.isPolaCount) {
        this.removeDescription()
      }
      this.isTimeOut = setTimeout(() => {
        this.numberPola = 0
        this.isPolaCount = false
      }, 2500)
    },
    ...mapActions({
      removeDescription: 'DocumentHandler/TOGGLE_DESC_LABEL'
    })
  }
}
</script>
